import React from 'react';

import { I18nextProvider } from 'react-i18next';
import createLoading from 'dva-loading';
import dva from 'dva';
import { message } from 'antd';
import createHistory from 'history/createBrowserHistory';
import { Amplify } from 'aws-amplify';
import envConfig from '@hulu/env-config';

import i18n from './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { app as appCommon } from './models/app';
import { adsTracer } from './models/adsTracer';
import { newTestCase } from './models/adsTracer/newTestCase';
import { adInvestigator } from './models/adInvestigator';
import { adCampaignProvider } from './models/adCampaignProvider';
import { creativeTracker } from './models/creativeTracker';
import { creativeTrackerLegacy } from './models/creativeTrackerLegacy';
import { Router } from 'dva/router';
import { durationManager } from './models/durationManager';
import { vastTracker } from './models/vastTracker';
import { reservationTool } from './models/reservationTool';
import { qCheck } from './models/qCheck';
import { configManager } from './models/configManager';

const app = dva({
  history: createHistory(),
  onError(e) {
    message.error(e.message, 10);
  },
});

Amplify.configure({
  Auth: {
    region: envConfig.REACT_APP_COGNITO_REGION,
    userPoolId: envConfig.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: envConfig.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: envConfig.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: envConfig.REACT_APP_COGNITO_REDIRECT_SIGN_IN || envConfig.REACT_APP_COGNITO_REDIRECT,
      redirectSignOut: envConfig.REACT_APP_COGNITO_REDIRECT_SIGN_OUT || envConfig.REACT_APP_COGNITO_REDIRECT,
      responseType: 'code',
    },
  },
});

app.use(createLoading());
app.model(appCommon);
app.model(adsTracer);
app.model(newTestCase);
app.model(adInvestigator);
app.model(durationManager);
app.model(adCampaignProvider);
app.model(creativeTracker);
app.model(creativeTrackerLegacy);
app.model(vastTracker);
app.model(reservationTool);
app.model(qCheck);
app.model(configManager);
app.router(({ history }) => (
  <Router history={history}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Router>
));
app.start('#root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
