import { Spin, Steps } from 'antd';
import React from 'react';
import { connect } from 'dva';

import { creativesColumnsLegacy, creativeTrackerExportFieldsLegacy } from '../../Templates/columns';
import { CreativesTableWrapper } from './CreativesTableWrapper';
import AssetDetailBox from './AssetDetailBox';
import { AssetBoxWrapper, LoadingTipWrapper } from './styles';
import {
  CREATIVETRACKER_TABLE,
  CREATIVETRACKER_TABLE_ASSET,
  CREATIVETRACKER_TABLE_ROW_LOADING,
  CREATIVETRACKER_TABLE_STEPS,
  PERMISSION_PLACEHOLDER,
} from '../../../testUtils';
import { AD_SERVER_LABEL_MAP } from '../../../models/creativeTrackerLegacy/constants';
import { ResizableTable } from '../../../utils';

const { Step } = Steps;

const TABLE_SCROLL_Y = { y: '60vh' };

const CreativesTable = ({
  dispatch,
  loading,
  assetLoading,
  permissions = PERMISSION_PLACEHOLDER,
  creatives = [],
  assets = {},
  creativeWidths = [],
}) => {
  const expandedRowRender = ({ adUnitKey, mediafileIdentifier }) => {
    const asset = assets[`${adUnitKey}:${mediafileIdentifier}`];
    const timeline =
      asset || !assetLoading ? (
        <AssetBoxWrapper>
          {/* Ingest Progress Steps */}
          <div data-testid={CREATIVETRACKER_TABLE_STEPS}>
            <Steps progressDot current={asset?.currentStepIndex} status={asset?.currentStatus}>
              {asset?.timeline.map(({ title, status, timestamp }) => (
                <Step key={title} title={title} status={status} description={timestamp} />
              ))}
            </Steps>
          </div>

          {/* Asset In-Details */}
          <div data-testid={CREATIVETRACKER_TABLE_ASSET}>
            <AssetDetailBox asset={asset} permissions />
          </div>
        </AssetBoxWrapper>
      ) : (
        <LoadingTipWrapper data-testid={CREATIVETRACKER_TABLE_ROW_LOADING}>
          <Spin tip="Fetching Creative Info..." />
        </LoadingTipWrapper>
      );

    return timeline;
  };

  const onExpand = (expanded, creative) => {
    if (expanded) dispatch({ type: 'creativeTrackerLegacy/fetchAssetDetails', payload: creative });
  };

  const handleWidthChange = (widths) =>
    dispatch({
      type: 'creativeTrackerLegacy/updateCreativeWidths',
      payload: widths,
    });

  return (
    <CreativesTableWrapper data-testid={CREATIVETRACKER_TABLE}>
      <Spin spinning={loading}>
        <ResizableTable
          inputColumns={creativesColumnsLegacy(creatives, creativeWidths)}
          dataSource={creatives
            .filter(({ adServer }) => permissions.can('access', adServer))
            .map((creative) => ({
              ...creative,
              key: creative.adUnitId + '_' + creative.lastSeen,
              adServer: AD_SERVER_LABEL_MAP.get(creative.adServer) ?? creative.adServer,
            }))}
          scroll={TABLE_SCROLL_Y}
          pagination={true}
          widthHandler={handleWidthChange}
          expandable={{
            expandedRowRender: expandedRowRender,
            onExpand: onExpand,
          }}
          searchableProps={null}
          exportableProps={{
            fields: creativeTrackerExportFieldsLegacy,
            fileName: 'CreativeTrackerSnapshot ' + new Date().toString(),
          }}
          refresh={null}
        />
      </Spin>
    </CreativesTableWrapper>
  );
};

function mapStateToProps({ app, creativeTrackerLegacy, loading }) {
  return {
    loading: loading.effects['creativeTrackerLegacy/pageInit'],
    assetLoading: loading.effects['creativeTrackerLegacy/fetchAssetDetails'],
    creatives: creativeTrackerLegacy.creatives,
    assets: creativeTrackerLegacy.assets,
    pathParams: app.pathParams,
    permissions: app.permissions,
    creativeWidths: creativeTrackerLegacy.creativeWidths,
  };
}

export default connect(mapStateToProps)(CreativesTable);
