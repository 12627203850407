import { MEDIA_FILE_URL_KEY, REJECTION_REASONS_KEY, ASSET_NAME_KEY } from '../CreativeTracker/constants';

export const formatAssetDetails = (record) => {
  let response = '';
  if (record[ASSET_NAME_KEY]) {
    response += 'Asset Name: ' + record[ASSET_NAME_KEY];
  }
  if (record[MEDIA_FILE_URL_KEY]) {
    response += '\nFilename: ' + record[MEDIA_FILE_URL_KEY];
  }
  if (record[REJECTION_REASONS_KEY] && record[REJECTION_REASONS_KEY].length > 0) {
    response += '\nRejection Reasons: ' + record[REJECTION_REASONS_KEY].join(', ');
  }
  return response.length > 0 ? response : 'N/A';
};
