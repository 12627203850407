import React from 'react';
import { Typography } from '@hulu/react-style-components';
import { connect } from 'dva';

import {
  AD_INVESTIGATOR,
  AD_INVESTIGATOR_TITLE,
  ADS_TRACER,
  ADS_TRACER_TITLE,
  UP_CREATIVE_TRACKER,
  CREATIVE_TRACKER_LEGACY,
  CREATIVE_TRACKER_TITLE,
  CREATIVE_TRACKER_TITLE_LEGACY,
  DURATION_MANAGER,
  DURATION_MANAGER_TITLE,
  RESERVATION_TOOL,
  RESERVATION_TOOL_TITLE,
  VAST_TRACKER,
  VAST_TRACKER_TITLE,
  Q_CHECK,
  Q_CHECK_TITLE,
  AUCTION_SNAPSHOT,
  AUCTION_SNAPSHOT_TITLE,
  VIEW,
  CONFIG_MANAGER_TITLE,
  CONFIG_MANAGER,
} from '../../constants';
import { CardGridWrapper, CardTitleWrapper, CardWrapper, DescriptionWrapper, ToolSelectionWrapper } from './styles';
import { Tag } from 'antd';

const ToolSelection = ({ dispatch, permissions }) => {
  const cards = [
    {
      title: ADS_TRACER_TITLE,
      description: 'Trace VOD & Live Ad Requests across End-to-end systems & review intermediary service calls.',
      link: ADS_TRACER,
    },
    {
      title: AD_INVESTIGATOR_TITLE,
      description:
        'Override Ad Selections with Preselected IDs defined by Pod/Slot indices on Account Profiles or Device IDs.',
      link: AD_INVESTIGATOR,
    },
    // TODO uncomment when ADS_METADATA_VIEWER ready
    // {
    //   title: ADS_METADATA_VIEWER_TITLE,
    //   description: 'Views Ads Metadata by Ad-level, Creative-level, Campaign-level, Targeting-Rules-level, Asset-level',
    //   link: ADS_METADATA_VIEWER,
    // },
    {
      title: CREATIVE_TRACKER_TITLE,
      description:
        'Track lifecycles of all received ad creatives, through pre-ingest, QC, post-QC, etc., & preview creatives directly.',
      link: UP_CREATIVE_TRACKER,
      beta: true,
    },

    {
      title: CREATIVE_TRACKER_TITLE_LEGACY,
      description:
        'Track lifecycles of all received ad creatives, through pre-ingest, QC, post-QC, etc., & preview creatives directly.',
      link: CREATIVE_TRACKER_LEGACY,
    },

    {
      title: VAST_TRACKER_TITLE,
      description: 'Review VAST deliveries by counts of their success/failure statuses in dashboard.',
      link: VAST_TRACKER,
    },
    {
      title: DURATION_MANAGER_TITLE,
      description: 'Configure & View Pod Durations to-fill rules for LIVE DAI by permutations of channel/series/OPD.',
      link: DURATION_MANAGER,
    },
    {
      title: RESERVATION_TOOL_TITLE,
      description: 'Reserve inventory splits ahead of time by channels & series for ad serving & review audit trails.',
      link: RESERVATION_TOOL,
    },
    {
      title: Q_CHECK_TITLE,
      description: 'Validate ads against Disney quality control standards.',
      link: Q_CHECK,
    },
    {
      title: CONFIG_MANAGER_TITLE,
      description: 'Manage Partner DAI Configs',
      link: CONFIG_MANAGER,
    },
    {
      title: AUCTION_SNAPSHOT_TITLE,
      description: 'Sample auctions from DRAX inventory to troubleshoot programmatic related issues.',
      link: AUCTION_SNAPSHOT,
    },
  ]
    .filter((card) => permissions.can(VIEW, card.link))
    .map(({ title, description, link, beta }, i) => (
      <CardWrapper
        tabIndex="0"
        key={`${title}-${i}`}
        bordered={false}
        onClick={() => dispatch({ type: 'app/updateSelectedTab', payload: link })}
        onKeyPress={(e) => {
          // on press enter key
          if (e.charCode === 13) {
            dispatch({ type: 'app/updateSelectedTab', payload: link });
          }
        }}
      >
        <h4>{beta ? <Tag color="success">beta</Tag> : null}</h4>
        <CardTitleWrapper>{title.toUpperCase()}</CardTitleWrapper>
        <DescriptionWrapper>{description}</DescriptionWrapper>
      </CardWrapper>
    ));

  return (
    <ToolSelectionWrapper>
      <Typography style={{ color: '#090699' }} variant="h1">
        Start by Selecting a Tool:
      </Typography>
      <br />
      <h2 style={{ fontSize: '18px', fontWeight: 600 }}>Learn about what each tool does & click to start</h2>
      <br />
      <CardGridWrapper>{cards}</CardGridWrapper>
    </ToolSelectionWrapper>
  );
};

function mapStateToProps(state) {
  return {
    permissions: state.app.currentUser.permissions,
  };
}

export default connect(mapStateToProps)(ToolSelection);
