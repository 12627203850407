import React, { Fragment, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'dva/router';
import { ConfigProvider } from 'antd';
import { colors } from '@hulu/design-tokens';
import './App.css';

import NavHeader from './pages/NavHeader';
import AdsTracer from './pages/AdsTracer';
import NewTestCase from './pages/AdsTracer/NewTestCase';
import AdInvestigator from './pages/AdInvestigator';
import CreateDurationRule from './pages/DurationManager/CreateRule';
import BulkUploadRules from './pages/DurationManager/BulkUploadRules';
import DurationManager from './pages/DurationManager';
import VastTracker from './pages/VastTracker';
import ReservationTool from './pages/ReservationTool';
import NotFound from './pages/NotFound';
import Authentication from './pages/Authentication';
import ToolSelection from './pages/ToolSelection';
import AuctionSnapshot from './pages/AuctionSnapshot/AuctionSnapshot';
import AuctionSnapshotDetailedView from './pages/AuctionSnapshot/AuctionSnapshotDetailedView/AuctionSnapshotDetailedView';
import QCheck from './pages/QCheck';
import {
  AD_INVESTIGATOR,
  ADS_TRACER,
  UP_CREATIVE_TRACKER,
  CREATIVE_TRACKER_LEGACY,
  DURATION_MANAGER_BULK_UPLOAD_PATH,
  DURATION_MANAGER_CREATE_RULE_PATH,
  DURATION_MANAGER_PATH,
  NEW_TEST_CASE,
  RESERVATION_TOOL,
  VAST_TRACKER,
  AUCTION_SNAPSHOT,
  AUCTION_SNAPSHOT_DETAILED_VIEW,
  Q_CHECK,
  DURATION_MANAGER,
  CONFIG_MANAGER,
  CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH,
  CONFIG_MANAGER_SOURCE_DETAILS_PATH,
} from './constants';
import CreativeTracker from './pages/CreativeTracker';
import CreativeTrackerLegacy from './pages/CreativeTrackerLegacy';

import './App.css';
import 'antd/dist/reset.css';
import { AppFooter, ContentLayout, GlobalStyle, MainLayout } from './styles';
import SecureRoute from './pages/SecureRoute';
import ConfigManager from './pages/ConfigManager';
import CreateSourceDetails from './pages/ConfigManager/CreateSourceDetails';
import SourceDetails from './pages/ConfigManager/SourceDetails';
import JwtTool from './pages/JwtTool';

ConfigProvider.config({ theme: { primaryColor: colors.colorBlack95 } });

export function App() {
  const [authenticated, setAuth] = useState(false);

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <BrowserRouter>
      <Fragment>
        <GlobalStyle />
        <MainLayout data-testid="app-root">
          <NavHeader authenticated={authenticated} />
          <ContentLayout>
            <Authentication authenticated={authenticated} setAuth={setAuth}>
              <Switch>
                <Route exact path="/" component={ToolSelection} />
                <Route exact path="/jwt" component={JwtTool} />
                <SecureRoute path={`/${ADS_TRACER}`} component={AdsTracer} tool={ADS_TRACER} />
                <SecureRoute path={`/${NEW_TEST_CASE}`} component={NewTestCase} tool={ADS_TRACER} />
                <SecureRoute path={`/${AD_INVESTIGATOR}`} component={AdInvestigator} tool={AD_INVESTIGATOR} />
                <SecureRoute
                  path={DURATION_MANAGER_CREATE_RULE_PATH}
                  component={CreateDurationRule}
                  tool={DURATION_MANAGER}
                />
                <SecureRoute
                  path={DURATION_MANAGER_BULK_UPLOAD_PATH}
                  component={BulkUploadRules}
                  tool={DURATION_MANAGER}
                />
                <SecureRoute path={DURATION_MANAGER_PATH} component={DurationManager} tool={DURATION_MANAGER} />
                {/* TODO uncomment when ads-metadata-viewer ready
            <Route path={`/${ADS_METADATA_VIEWER}`} component={AdsMetadataViewer} />*/}
                <SecureRoute path={`/${UP_CREATIVE_TRACKER}`} component={CreativeTracker} tool={UP_CREATIVE_TRACKER} />
                <SecureRoute
                  path={`/${CREATIVE_TRACKER_LEGACY}`}
                  component={CreativeTrackerLegacy}
                  tool={CREATIVE_TRACKER_LEGACY}
                />
                <SecureRoute path={`/${VAST_TRACKER}`} component={VastTracker} tool={VAST_TRACKER} />
                <SecureRoute path={`/${RESERVATION_TOOL}`} component={ReservationTool} tool={RESERVATION_TOOL} />
                <SecureRoute path={`/${AUCTION_SNAPSHOT}`} component={AuctionSnapshot} tool={AUCTION_SNAPSHOT} />
                <SecureRoute
                  path={`/${AUCTION_SNAPSHOT_DETAILED_VIEW}/:id`}
                  component={AuctionSnapshotDetailedView}
                  tool={AUCTION_SNAPSHOT}
                />
                <SecureRoute path={`/${Q_CHECK}`} component={QCheck} tool={Q_CHECK} />
                <SecureRoute
                  path={CONFIG_MANAGER_SOURCE_DETAILS_PATH}
                  component={SourceDetails}
                  tool={CONFIG_MANAGER}
                />
                <SecureRoute
                  path={CONFIG_MANAGER_CREATE_SOURCE_DETAILS_PATH}
                  component={CreateSourceDetails}
                  tool={CONFIG_MANAGER}
                />
                <SecureRoute path={`/${CONFIG_MANAGER}`} component={ConfigManager} tool={CONFIG_MANAGER} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Authentication>
          </ContentLayout>
          <AppFooter>Copyright © {currentYear} Walt Disney Company - All Rights Reserved</AppFooter>
        </MainLayout>
      </Fragment>
    </BrowserRouter>
  );
}

export default App;
