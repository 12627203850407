import { Typography } from '@hulu/react-style-components';
import { Button, Col, Form, Popover, Row, Select, Space, Tag } from 'antd';
import moment from 'moment';
import { connect } from 'dva';
import { useEffect, useState } from 'react';

import { SearchFilterWrapper, StyledButton } from './styles';
import { DATA_TYPE, EMPTY_VALUES, SELECT_MODE, STATUSES } from '../../../constants';
import { camelCaseToSentenceCase, MomentDatePicker } from '../../../utils';
import {
  CREATIVETRACKER_SEARCH,
  CREATIVETRACKER_SEARCH_BUTTONS,
  CREATIVETRACKER_SEARCH_FORM,
} from '../../../testUtils';
import { INITIAL_FILTER_STATE, INITIAL_SELECTED_VALUES } from '../../../models/creativeTrackerLegacy';
import { InfoCircleOutlined } from '@ant-design/icons';
import { INGESTION_STATUS_TO_DESCRIPTION_MAP } from '../constants';

const { RangePicker } = MomentDatePicker;

const IngestionStatusInfo = () => {
  const rowStyle = {
    background: '#fafafa',
    margin: '10px 0 10px 0',
    padding: '5px 0 5px 0',
  };

  const statusDescriptionRender = (status, description) => (
    <Row style={rowStyle} align="middle" key={status}>
      <Col span={8}>
        <Tag color={STATUSES[status].color}>{status}</Tag>
      </Col>
      <Col span={16}>
        <p style={{ marginBottom: '0' }}>{description}</p>
      </Col>
    </Row>
  );

  const content = (
    <>
      {Array.from(INGESTION_STATUS_TO_DESCRIPTION_MAP, ([status, description]) => {
        return statusDescriptionRender(status, description);
      })}
    </>
  );

  return (
    <Popover
      trigger="hover"
      placement="right"
      overlayStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '600px' }}
      title={<h3>Ingestion Status Description</h3>}
      content={content}
    >
      <InfoCircleOutlined />
    </Popover>
  );
};

const SearchFilter = ({ dispatch, filterData = INITIAL_FILTER_STATE }) => {
  const [form] = Form.useForm();
  const [isShowAllFilters, setShowAllFilters] = useState(false);
  const toggleShowAllFilters = () => setShowAllFilters(!isShowAllFilters);

  useEffect(() => {
    return () => {
      // reset fields during unmounting
      resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    dispatch({ type: 'creativeTrackerLegacy/updateFilterSelected', payload: values });
    dispatch({ type: 'creativeTrackerLegacy/pageInit' });
  };

  const rangePicker = (field, selected) => (
    <RangePicker
      defaultValue={selected}
      onChange={(vals) => {
        form.setFieldsValue({ [field]: [vals?.[0].startOf('day'), vals?.[1].endOf('day')] });
      }}
      ranges={{
        Today: [moment().startOf('day'), moment().endOf('day')],
        'Last 2 Days': [moment().startOf('day').subtract(2, 'day'), moment().endOf('day')],
        'Last 7 Days': [moment().startOf('day').subtract(7, 'day'), moment().endOf('day')],
        'Last 30 Days': [moment().startOf('day').subtract(30, 'day'), moment().endOf('day')],
      }}
      format="MM/DD/YYYY"
    />
  );

  const multiSelect = (field, selectMode = SELECT_MODE.TAGS, selected, options, dataType) => (
    <Select
      allowClear
      mode={selectMode}
      notFoundContent={EMPTY_VALUES}
      tokenSeparators={[',']}
      placeholder={camelCaseToSentenceCase(field)}
      defaultValue={selected}
      onChange={(vals) =>
        dataType === DATA_TYPE.NUMBER
          ? form.setFieldsValue({ [field]: vals.map((val) => Number(val)) })
          : form.setFieldsValue({ [field]: vals })
      }
      options={options}
      optionFilterProp={'label'}
    />
  );

  const fillout = (field, { selectMode, selected, options, dataType, rules = [] }) => (
    <Form.Item key={field} name={field} rules={rules}>
      <>
        <Typography variant="h8" style={{ marginRight: '3px' }}>
          {camelCaseToSentenceCase(field)}
        </Typography>
        {field === 'statuses' && <IngestionStatusInfo />}
        {field.endsWith('Range')
          ? rangePicker(field, selected)
          : multiSelect(field, selectMode, selected, options, dataType)}
      </>
    </Form.Item>
  );

  // Create Fillouts
  let fillouts = [];
  const filterDataAsArray = Object.entries(filterData);
  if (isShowAllFilters) {
    fillouts = filterDataAsArray.map(([field, data]) => fillout(field, data));
  } else {
    for (let i = 0; i < 6; i++) {
      fillouts.push(fillout(filterDataAsArray[i][0], filterDataAsArray[i][1]));
    }
  }

  const resetFields = () => {
    dispatch({ type: 'creativeTrackerLegacy/resetFields' });
    form.resetFields();
  };

  return (
    <SearchFilterWrapper data-testid={CREATIVETRACKER_SEARCH}>
      <Typography variant="h4">Filter By Field</Typography>
      <br />
      <Form form={form} onFinish={onFinish} initialValues={INITIAL_SELECTED_VALUES}>
        {/* Fillout Sections */}
        <div data-testid={CREATIVETRACKER_SEARCH_FORM}>{fillouts}</div>

        {/* Fillout Buttons */}
        <div data-testid={CREATIVETRACKER_SEARCH_BUTTONS}>
          <StyledButton size="small" onClick={toggleShowAllFilters}>
            {`Show ${isShowAllFilters ? 'less' : 'more'} filters`}
          </StyledButton>
          <br />
          <Form.Item>
            <Space>
              <Button htmlType="button" onClick={resetFields}>
                Reset
              </Button>
              <Button key="submit" type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </SearchFilterWrapper>
  );
};

function mapStateToProps({ creativeTrackerLegacy }) {
  return { filterData: creativeTrackerLegacy.filters };
}

export default connect(mapStateToProps)(SearchFilter);
