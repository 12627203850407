export const AD_ID_KEY = 'ad-id';
export const SOURCE_AD_ID_KEY = 'source-ad-id';
export const SOURCE_ASSET_ID_KEY = 'source-asset-id';
export const MEDIA_FILE_URL_KEY = 'mediafile-url';
export const BRAND_NAME_KEY = 'brand-name';
export const BRAND_NAMES_KEY = 'brand-names';
export const CAMPAIGN_MANAGER_NAME_KEY = 'campaign-manager-email';
export const UPDATED_AFTER_DATE_KEY = 'updated-after-date';
export const UPDATED_AT_KEY = 'updated-at';
export const STATUS_KEY = 'transcode-status';
export const ASSET_ID_KEY = 'asset-id';
export const ASSET_NAME_KEY = 'asset-name';
export const ASSET_DETAILS_KEY = 'asset-details';
export const DSP_KEY = 'dsp-name';
export const SOURCE_KEY = 'source';
export const REJECTION_REASONS_KEY = 'rejection-reasons';
export const INGESTION_TYPE_KEY = 'ingestion-type';
export const CONTENT_PARTNER_ID_KEY = 'content-partner-id';
export const CREATIVE_TYPE_KEY = 'creative-type';
export const CREATIVE_TYPES_KEY = 'creative-types';
export const PUBLISHER_KEY = 'publisher';
export const COUNTRY_CODES_KEY = 'country-codes';
